
 <div style="margin:10px 20px;">
    <!-- <div>
        <mat-form-field style="width:300px;">
            <mat-label>New category</mat-label>
            <input  type="text" [(ngModel)]="new_category" matInput placeholder="Category name">
        </mat-form-field>
        <button (click)="savenew()" mat-icon-button aria-label="Save">
            <mat-icon>add</mat-icon>
        </button>
     </div> -->
     <div >
        <table mat-table [dataSource]="products" class="mat-elevation-z8" style="width: 100%;">
            <colgroup>
                <col style="width:18%">
                <col style="width:30%">
                <col style="width:20%">
                <col style="width:30%;">
                <col >
                <col>
            </colgroup>
            <ng-container matColumnDef="product_code">
              <th mat-header-cell *matHeaderCellDef> Product Code </th>
               
              <ng-container *matCellDef="let i;let idx = index;">
                <td  mat-cell *ngIf="!i.editing"> 
                    {{i.productCode}}
                </td>
                <td  mat-cell *ngIf="i.editing" colspan="5" > 

                    <div style="display: flex;flex-direction: column;padding:5px;width: 400px;margin: 20px auto;" class="mat-elevation-z2" >
                        <mat-form-field appearance="fill">
                            <mat-label>Product Code</mat-label>
                            <input [disabled]="!i.isnew" matInput type="text" [(ngModel)]="i.productCode">
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Name</mat-label>
                            <input matInput type="text" [(ngModel)]="i.productName">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Category</mat-label>
                            <mat-select [(ngModel)]="i.categoryId">
                                <mat-option *ngFor="let cat of i.categories" [value]="cat.id">
                                    {{cat.category}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Description</mat-label>
                            <textarea matInput [(ngModel)]="i.description"></textarea>
                        </mat-form-field>
                        <button (click)="save(i,idx)" mat-flat-button color="primary" aria-label="Save" style="border-radius: 0;">
                            <span *ngIf="i.isnew" >Save</span> 
                            <span *ngIf="!i.isnew">Update</span>
                        </button>
                        <button (click)="cancel(i,idx)" mat-flat-button color="basic" aria-label="Save" style="border-radius: 0;">
                            Cancel
                        </button>
                    </div>

                </td>
              </ng-container>
            </ng-container>
            <ng-container matColumnDef="product_name">
                <th mat-header-cell *matHeaderCellDef>Product Name</th>
                <ng-container *matCellDef="let i">
                    <td mat-cell *ngIf="!i.editing">
                        {{i.productName}}
                    </td>
                </ng-container>
              </ng-container>
              
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>Category</th>
                <ng-container *matCellDef="let i">
                    <td  mat-cell *ngIf="!i.editing"> 
                        {{i.categoryName}}
                    </td>
                </ng-container>
  
              </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <ng-container *matCellDef="let i">
                    <td mat-cell *ngIf="!i.editing">
                        {{i.description}}
                    </td>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef>
                    <button (click)="new()" mat-icon-button aria-label="Save">
                        <mat-icon>add</mat-icon>
                    </button>
                </th>
                <ng-container *matCellDef="let i;let idx = index">
                    <td mat-cell>  
                        <div class="padding:5px;">
                         <button *ngIf="!i.editing" (click)="edit(i,idx)" mat-icon-button color="basic" aria-label="Edit">
                             <mat-icon>edit</mat-icon>
                         </button>
                        </div>
                     </td>
                </ng-container>
                
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="table_cols"></tr>
            <tr mat-row *matRowDef="let row; columns: table_cols;"></tr>
          </table>
     </div>
 </div>
 
  