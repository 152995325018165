import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Route, Router, Params, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.css']
})
export class ChangePasswordPageComponent implements OnInit {
  
  form = this.fb.group({
    password : [''],
    new_password:['',Validators.required],
  });
  userid: any;
  oldPasswordRequired = true;
  error_message = '';

  constructor(private fb : FormBuilder,
    private userService : UserService,
    private route: ActivatedRoute,
    private router : Router,
    
    ){
    
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.userid = params.get('userid');
      this.userService.user_changePasswordMethod(this.userid).subscribe(
        (s:any)=>{
          this.oldPasswordRequired = s.oldPasswordRequired;
        },
        e=> alert(Util.ApiError(e))
      )
    });
    
  }

  savePassword(){
    let password = this.form.controls.password.value;
    let new_password = this.form.controls.new_password.value;
    if(new_password==null || new_password.length<6)
      this.error_message = 'New password should be atleast 6 characters.';
    this.userService.user_changePassword(this.userid,password,new_password).subscribe(
      s=>{
        this.router.navigateByUrl('/');
      },
      e=> alert(Util.ApiError(e))
    )
  }

}
