import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './layouts/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { HeadComponent } from './layouts/head.component';
import { LeftPanelComponent } from './layouts/left-panel.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CarticonComponent } from './components/carticon/carticon.component';
import { CartpageComponent } from './pages/cartpage/cartpage.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { OrderDtlComponent } from './pages/order-dtl/order-dtl.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { EmailSetupComponent } from './pages/email-setup/email-setup.component';
import { ProductCategoriesComponent } from './pages/product-categories/product-categories.component';
import { ProductsComponent } from './pages/products/products.component';
import { AuthInterceptor } from './auth-interceptor';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { AppConfig } from './config';
import { ChangePasswordPageComponent } from './pages/change-password-page/change-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { OrderlistPageComponent } from './pages/orderlist-page/orderlist-page.component';

const appInitializerFn = (appConfig: AppConfig) => {
  return () => {
    return appConfig.loadConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    OrderPageComponent,
    HeadComponent,
    LeftPanelComponent,
    CarticonComponent,
    CartpageComponent,
    CheckoutPageComponent,
    OrderDtlComponent,
    AdminPageComponent,
    EmailSetupComponent,
    LoginComponent,
    UserManagementComponent,
    EmailSetupComponent,
    ProductCategoriesComponent,
    ProductsComponent,
    UnauthorizedComponent,
    ChangePasswordPageComponent,
    ResetPasswordPageComponent,
    OrderlistPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    
  ],
  providers: [AppConfig,
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [AppConfig] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
