<mat-form-field appearance="fill" style="width: 100%;">
  <mat-label>Filter by category</mat-label>
  <mat-select [(ngModel)]="selected_category" (ngModelChange)="filter_category()">
    <mat-option>All categories</mat-option>
    <mat-option [value]="i.id" *ngFor="let i of categories">{{ i.category }}</mat-option>
  </mat-select>
</mat-form-field>

<div *ngIf="products">
  <table mat-table [dataSource]="filtered_products" class="mat-elevation-z1" style="width: 100%;">
    <colgroup>
      <col style="width: 90%;">
      <col>
    </colgroup>
    <ng-container matColumnDef="productName">
      <!-- <th mat-header-cell *matHeaderCellDef> Product </th> -->
      <td mat-cell *matCellDef="let i" style="padding-left: 0;">
        <button mat-button (click)="addtocart(i)" style="width: 100%;text-align: left;">
          <span>{{i.productName}}</span><br>
          <span style="font-weight: lighter;">{{i.categoryName}}-{{i.productCode}}</span>
          <label class="error-message" style="margin-left: 15px;">{{i.error_message}}</label>
        </button>
       
      </td>
    </ng-container>
    <ng-container matColumnDef="cart">
      <!-- <th mat-header-cell *matHeaderCellDef> </th> -->
      <td mat-cell *matCellDef="let element">
        <button (click)="addtocart(element)" mat-mini-fab aria-label="Cart" color="accent">
          <mat-icon>add_shopping_cart</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- <tr mat-header-row *matHeaderRowDef="table_cols"></tr> -->
    <tr mat-row *matRowDef="let row; columns: table_cols;"></tr>
  </table>
  <br><br>
</div>