import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-setup',
  templateUrl: './email-setup.component.html',
  styleUrls: ['./email-setup.component.css']
})
export class EmailSetupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
