
 <div style="margin:10px 20px;">
    <div>
        <mat-form-field style="width:300px;">
            <mat-label>New category</mat-label>
            <input  type="text" [(ngModel)]="new_category" matInput placeholder="Category name">
        </mat-form-field>
        <button (click)="savenew()" mat-icon-button aria-label="Save">
            <mat-icon>add</mat-icon>
        </button>
     </div>
     <div class="mat-elevation-z8">
        <table mat-table [dataSource]="categories" class="mat-elevation-z1" style="width: 100%;">
            <colgroup>
              <col style="width:10%">
              <col style="width: 80%;">
              <col>
            </colgroup>
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> Id </th>
              <td mat-cell *matCellDef="let i"> 
                  {{i.id}}
              </td>
            </ng-container>
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef>Category</th>
              <td mat-cell *matCellDef="let i">  
                  <label *ngIf="!i.editing">{{i.category}}</label>
                  
                  <mat-form-field *ngIf="i.editing" style="width:100%;padding-top:5px;">
                    <mat-label>Category Name</mat-label>
                    <input  type="text" [(ngModel)]="i.category" matInput placeholder="Category name">
                  </mat-form-field>
                
              </td>
            </ng-container>
            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let i">  
                   <div class="padding:5px;">
                    <button *ngIf="!i.editing" (click)="edit(i)" mat-icon-button color="basic" aria-label="Edit">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="i.editing" (click)="save(i)" mat-mini-fab color="accent" aria-label="Save">
                        <mat-icon>save</mat-icon>
                    </button>
                   </div>
                </td>
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="table_cols"></tr>
            <tr mat-row *matRowDef="let row; columns: table_cols;"></tr>
          </table>
     </div>
 </div>
 
  