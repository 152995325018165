import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { OrderService } from 'src/app/services/order.service';
import { Util } from 'src/app/util';
import { ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {

  setting_orderemails:any;
  separatorKeysCodes = [ENTER, SPACE, TAB];

  get orderemails():string{
    return this.setting_orderemails;
  }

  constructor(private orderService: OrderService, private _snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.orderService.settings_orderemail_get().subscribe(
      s=>{

        this.setting_orderemails = s;

      },
      e=> alert(Util.ApiError(e))
    )
  }

  
  orderemails_add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      this.setting_orderemails.push({email: value});
    }
    // Clear the input value
    event.input.value = '';
  }

  orderemails_remove(item:any): void {
    const index = this.setting_orderemails.indexOf(item);
    if (index >= 0) {
      this.setting_orderemails.splice(index, 1);
    }
  }
  orderemails_save(){
    this.orderService.settings_orderemail_save(this.setting_orderemails).subscribe(
      s=>{
        this.orderService.settings_orderemail_get().subscribe(
          ss=>{
            this.setting_orderemails = ss;
            this._snackBar.open("Setting saved successfully.",'Ok');
          }
        )
      },
      e=> alert(Util.ApiError(e))
    )
  }
 

}
