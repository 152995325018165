import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AppConfig } from '../config';
import { BaseService } from './base.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {
  
  constructor(private userService :UserService, public http : HttpClient, public config: AppConfig
    ) {
    super(config,http);
  }

  getAllCategories(){
    return this.http.get(this.ApiUrl('product/categories'));
  }

  product_getall(){
    return this.http.get(this.config.api_url+'product/getall');
  }
  product_get(product_code:any){
    return this.http.get(this.config.api_url+'product?product_code='+product_code);
  }

  cart_get(){
    return this.http.get(this.config.api_url + 'cart');
  }
  cart_getcount(){
    if(!this.userService.IsAuthenticated)
      return of(0);
    return this.http.get(this.config.api_url + 'cart/itemcount');
  }
  cart_additem(product_code:string, qty:number){

    let order = {
        productCode : product_code,
        qty: qty
      };

    return this.http.post(this.ApiUrl('cart/additem'),order);
  }

  cart_updateitem(product_code:string, qty:number){
    let item = {
      productCode : product_code,
      qty: qty
    };
    return this.http.post(this.config.api_url + 'Cart/updateitem',item);
  }

  cart_removeitem(product_code:string){
    let item = {
      productCode : product_code
    };
    return this.http.post(this.config.api_url + 'cart/removeitem',item);
  }

  order_save(order:any){
    return this.http.post(this.ApiUrl('order/save'),order);
  }
  order_get(reference_no:string){
    return this.http.get(this.config.api_url + 'order?ref_no='+reference_no);
  }
  order_email(reference_no:string){
    return this.http.get(this.config.api_url + 'order/emailorder?ref_no='+reference_no);
  }

  categories_getall(){
    return this.http.get(this.ApiUrl('category/getall'));
  }
  
  category_edit(cat:any){
    return this.http.post(this.ApiUrl('category/edit'),cat);
  }
  category_save(category_name:any){
    return this.http.post(this.ApiUrl('category/add'),{category:category_name});
  }
  product_edit(product:any){
    return this.http.post(this.ApiUrl('product/edit'),product);
  }
  product_save(product:any){
    return this.http.post(this.ApiUrl('product/add'),product);
  }

  settings_orderemail_get(){
    return this.http.get(this.ApiUrl('ref/order_emails'));
  }
  settings_orderemail_save(emails:any){
    return this.http.post(this.ApiUrl('settings/orderemail_save'),emails);
  }
}
