import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AppConfig } from '../config';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  public resetPasswordForEmail = '';
  
  constructor(public config: AppConfig, public http: HttpClient) {
    super(config,http);
  }
  
  public get UserId() : string | null {
    let user = localStorage.getItem("user");
    if(user==null)
      return null;
    return JSON.parse(user).userId;
  }
  public get UserName() : string | null {
    let user = localStorage.getItem("user");
    if(user==null)
      return null;
    return JSON.parse(user).userName;
  }

  public get IsAuthenticated() : boolean {
    return this.UserId!=null;
  }

  public get IsAdmin() : boolean {
    let user = localStorage.getItem("user");
    if(user==null)
      return false;
    return JSON.parse(user)?.role == "role_admin";
  }
  

  private get AuthToken():string | null{
    let user = localStorage.getItem("user");
    if(user==null)
      return null;
    return JSON.parse(user).token;
  }

  public header() {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    if(this.IsAuthenticated)
      header = header.append('Authorization', 'Bearer ' + this.AuthToken); 
    return { headers: header };
  }

  // public login2(email:string, password:string){
  //   return this.http.post(this.ApiUrl("user/login"), {email:email , password:password});
  // }
  public login(email:string, password:string):Observable<boolean>{
    return this.http.post(this.ApiUrl("login"), {email:email , password:password}).pipe(
      switchMap(s=>{
        let auth = s as any;
        this.store_auth(s);
        return of(true)
      }))
  }

  public logout(){
    localStorage.removeItem("user");
  }
  public store_auth(user:any){
    localStorage.removeItem("user");
    localStorage.setItem("user",JSON.stringify(user));
  }

  public user_getall(){
    return this.http.get(this.ApiUrl("user/getall"));
  }

  public getUser(userid:any){
    return this.http.get(this.ApiUrl("user/get?userid="+userid));
  }

  public edit(user:any){
    return this.http.post(this.ApiUrl("user/edit"),user);
  }

  public add(user:any){
    return this.http.post(this.ApiUrl("user/add"),user);
  }

  public resetPassword(userid:any){
    return this.http.post(this.ApiUrl("user/resetpassword"),{userid:userid});
  }
  public roles_getall(){
    return this.http.get(this.ApiUrl("ref/user_roles"));
  }

  public user_resetpass(email:any){
    return this.http.get(this.ApiUrl("resetpass?email="+email));
  }
  public user_resetpass2(email:any, otp:any){
    return this.http.post(this.ApiUrl("resetpass2"),{
      email : email,
      otp : otp,
    });
  }
  public user_requestChangePass(userid:any){
    return this.http.get(this.ApiUrl("user/requestchangepass?userid="+userid));
  }
  public user_changePasswordMethod(userid:any){
    return this.http.get(this.ApiUrl("changepasswordmethod?userid="+userid));
  }

  public user_changePassword(userid:any, current_password: any, new_password:any){
    return this.http.post(this.ApiUrl("changepassword"),{
      userId : userid,
      oldPassword : current_password,
      newPassword : new_password,
    });
  }

  
  
}
