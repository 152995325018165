import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import {catchError} from 'rxjs/operators'
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Util } from 'src/app/util';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form = this.fb.group({
    email :['', Validators.email],
    password :['']
  });
  error_message ='';

  constructor(private fb : FormBuilder, private userService :UserService,
    private router:Router,
    private route: ActivatedRoute) {
      
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.error_message = params['error_message'];
    });
  }
  login(){
    let email = this.form.controls.email.value;
    let password = this.form.controls.password.value;
    this.error_message = '';
    this.userService.login(email,password).subscribe(
      d => {
        this.router.navigateByUrl('/order');
      },
      e=> this.error_message =  Util.ApiError(e))
  }

  resetPassword(){

    if(this.form.controls.email.invalid || this.form.controls.email.pristine){
      alert("Please enter a valid registered email address.");
      return;
    }

    let email = this.form.controls.email.value;
    this.userService.resetPasswordForEmail = email;
  
    this.router.navigateByUrl("/resetpassword?email="+email);
    
  }

}
