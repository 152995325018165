
<div *ngIf="table_data_ready" style="margin:10px 20px;">

    <table mat-table [dataSource]="cart_items" class="mat-elevation-z3" style="width: 100%;">
      <colgroup>
        <col style="width: 15%;">
        <col>
        <col style="width: 150px;">
        <col style="width: 120px;">
        <col style="width: 50px;">
      </colgroup>
      <ng-container matColumnDef="product_code">
        <th mat-header-cell *matHeaderCellDef> Product Code </th>
        <td mat-cell *matCellDef="let i"> 
            {{i.productCode}}
        </td>
      </ng-container>
      <ng-container matColumnDef="product_name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let i"> 
            {{i.productName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let i"> 
            {{i.categoryName}}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Quantity </th>
        <td mat-cell *matCellDef="let i; let idx = dataIndex" style="text-align: center;"> 
            <ng-container>
                <button (click)="qtyDown(i)" mat-flat-button aria-label="minus" color="basic" class="btn-stepper" style="margin-right: 5px;">
                    <mat-icon  inline=true>remove</mat-icon>
                </button> 
              <input type="number" [value]="i.qty" (keyup)="qtyChanged(i,$event)" (focusout)="qtyLostFocus(i,$event)" min="1" style="width: 20pt; text-align: center; margin-right: 5px;" >
                <button (click)="qtyUp(i)" mat-flat-button color="basic" aria-label="plus"   class="btn-stepper">
                    <mat-icon inline=true >add</mat-icon>
                </button>  
            </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let i; let idx = index;">  
          <button mat-icon-button aria-label="trash" (click)="remove(idx)" >
            <mat-icon>delete</mat-icon>
          </button> </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="table_cols"></tr>
      <tr mat-row *matRowDef="let row; columns: table_cols;"></tr>
    </table>

    <div style="margin-top:10px;padding: 10px;" class="mat-elevation-z8">
      <br>
      <div>
        <p>Products : {{total_item}}<br>Total Quantity: {{total_qty}}</p>
      </div>
      <div>
        <button mat-flat-button *ngIf="cart_items && cart_items.data.length" color="primary" (click)="checkout()">Checkout</button>
      </div>
      <br>
    </div>
   
  </div>

 
