import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router  , ParamMap } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-dtl',
  templateUrl: './order-dtl.component.html',
  styleUrls: ['./order-dtl.component.css']
})
export class OrderDtlComponent implements OnInit {

  order: any;
  items!:any;
  ref_no!: any;

  constructor(private orderService : OrderService, 
    private router: Router, private route : ActivatedRoute,
    private snackBar : MatSnackBar) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params=>{ this.ref_no = params.get('ref_no') });
    this.orderService.order_get(this.ref_no)
    .subscribe((order:any)=>{
      this.order = order.order;
      this.order.shipmentModeDesc = order.shipmentModeDesc;
      if(this.order) this.items = order.items;
    });
  }
  email(){
    this.orderService.order_email(this.ref_no).subscribe(
      s=>{
        this.snackBar.open('Order e-mail sent.','Okay',{duration:3000});
      }
    )
  }
}
