

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.css']
})
export class ResetPasswordPageComponent implements OnInit {

  form = this.fb.group({
    otp : ['',Validators.required]
  });

  error_message='';
  email:any;
  show_confirmreset = true;
  show_reset_section = false;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router, 
    private route: ActivatedRoute) { 
    this.email = userService.resetPasswordForEmail;
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params=>{ this.email = params.get('email') });
  }

  confirmReset(){
    this.userService.user_resetpass(this.email).subscribe(
      s=>{
        this.show_confirmreset = false;
        this.show_reset_section = true;
      },
      e=> alert(Util.ApiError(e))
    )
  }

  reset(){
    let otp = this.form.controls.otp.value;
    this.userService.user_resetpass2(this.email, otp).subscribe(
      (s:any)=>{
        this.router.navigateByUrl("/changepassword?userid="+s.userId);
      },
      e=> alert(Util.ApiError(e))
    )
  }
  

}
