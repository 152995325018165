import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(public config: AppConfig, public http : HttpClient) { }

  ApiUrl(api_endpoint:string){
    return this.config.api_url + api_endpoint;
  }
}
