import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {

  refresh = new Subject<Number>();
  refresh_cart = new Subject<Number>();
  constructor() { }

  
}
