import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { Util } from 'src/app/util';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  table_source = new MatTableDataSource;
  table_cols =['user_id','email','fullname','active','tools']
  user_roles: any;


  constructor(private userService : UserService) { }

  ngOnInit(): void {
   
    this.userService.user_getall().subscribe(
      s=>{
        this.table_source.data = s as any;
      },
      e=> alert(Util.ApiError(e))
    )
    this.userService.roles_getall().subscribe(
      s=>{
        this.user_roles = s as any;
      },
      e=> alert(Util.ApiError(e))
    )

  }
  
  map_item_properties(dest_item:any,source_item:any ){
    dest_item.user_id = source_item.user_id;
    dest_item.email = source_item.email;
    dest_item.fullname =source_item.fullname;
    dest_item.username =  source_item.username;
    dest_item.active = source_item.active;
    dest_item.role = source_item.role;
    dest_item.roleName =  source_item.roleName;
  }
  
  item_currently_edited : any;
  item_currently_edited_index: any;
  edit(item:any,index:any){
    
    if(this.item_currently_edited){
      this.cancel_edit(this.item_currently_edited,this.item_currently_edited_index);
    }
    this.item_currently_edited = item;
    this.item_currently_edited_index = index;

    item.editing = true;
    if(!item.user_roles)
      item.user_roles = this.user_roles;
  }

  cancel_edit(item:any,index:number){
    item.editing = false;
    if(item.isnew){
      let data = this.table_source.data;
      data.splice(index,1);
      this.table_source.data = data;
    }else{
      this.userService.getUser(item.user_id).subscribe(
        (s:any)=>{
          this.map_item_properties(item,s);
        }
      )
    }
  }

  new(){

    let new_item = {
      isnew : true,
      user_roles : this.user_roles,
      active : true,
    };
    let data = this.table_source.data;
    let new_item_index = data.splice(0,0,new_item);
    this.table_source.data = data;
    this.edit(new_item,new_item_index);
  }

  save(item:any, index:number){
    if(item.isnew){
      this.userService.add(item).subscribe(
        (s:any)=>{
          this.map_item_properties(item,s);
          item.isnew = false;
          item.editing = false;
        },
        (e:any)=> alert(Util.ApiError(e))
      )
    }
    else{
      this.userService.edit(item).subscribe(
        (s:any)=>{
          this.map_item_properties(item,s);
          item.editing = false;
        },
        (e:any)=> alert(Util.ApiError(e))
      )
    }
  }
 

}
