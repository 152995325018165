import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "./services/user.service";

@Injectable({
    providedIn: 'root'
  })
  export class AuthGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) {}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if(this.userService.IsAuthenticated)
        return true;
      return this.router.navigate(['/login']);
    }
  }


  @Injectable({
    providedIn: 'root'
  })
  export class AdminRoleGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) {}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if(this.userService.IsAdmin)
        return true;
      return this.router.navigateByUrl('/unauthorized')
    }
  }
  