<div style="margin:10px 20px;">
    <table class="settings mat-elevation-z1">
        <colgroup>
            <col style="width: 15%;">
        </colgroup>
        <thead>
            <tr>
                <th>Setting</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
         <tr>
             <td>
                 <label>Order Email</label>
             </td>
             <td>
                <mat-form-field class="example-chip-list" style="width: 100%;">
                    <mat-label>Order Emails</mat-label>
                    <mat-chip-list #chipList>
                      <mat-chip *ngFor="let i of setting_orderemails" [selectable]="true"
                               [removable]="true" (removed)="orderemails_remove(i)">
                        {{i.email}}
                        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                      </mat-chip>
                      <input placeholder="Add email..."
                             [matChipInputFor]="chipList"
                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                             [matChipInputAddOnBlur]="true"
                             (matChipInputTokenEnd)="orderemails_add($event)">
                    </mat-chip-list>
                  </mat-form-field> 
                <button (click)="orderemails_save()" mat-flat-button color="primary" aria-label="Save" >
                    Save
                </button>
             </td>
         </tr>
        </tbody>
     </table>
</div>
