import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavBarService } from 'src/app/services/navbar.service';
import { OrderService } from 'src/app/services/order.service';


@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css']
})
export class OrderPageComponent implements OnInit {

  products: any;
  filtered_products: any;
  categories: any[] =[];
  selected_category!: any;
  table_cols = ['productName','cart'];

  constructor(private orderService : OrderService, private headerService: NavBarService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    
    this.orderService.getAllCategories()
    .subscribe(categories=>{
      if(categories)
        this.categories = categories as Array<any>;
      this.orderService.product_getall()
      .subscribe(products =>{
        if(products){
          this.products = products as Array<any>;
          this.filtered_products = this.products;
        }
      })
    });
  }

  filter_category(){
    this.filtered_products = this.products.filter((s:any)=> !this.selected_category || s.categoryId==this.selected_category);
  }
  addtocart(product:any){
    this.orderService.cart_additem(product.productCode,10).subscribe(response=>{
      if(response)
        this.snackBar.open(`Product ${product.productName} added to cart.`,'Okay',{duration:2000});
      else {
        product.error_message = "Product already in cart.";
        setTimeout(()=>{
          product.error_message ='';
        },3000);
        //this.snackBar.open(`Product already in cart.`,'Okay',{duration:2000}); 
      }
      this.headerService.refresh_cart.next();
    });
  }

}
