
<form (ngSubmit)="savePassword()" [formGroup]="form" style="width: 300px;margin: auto;margin-top: 30px;">
    <label>Change Password</label>
    <mat-form-field appearance="fill" *ngIf="oldPasswordRequired">
        <mat-label>Current Password</mat-label>
        <input type="password" matInput formControlName="password" 
        placeholder="Current Password">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>New Password</mat-label>
        <input type="password" matInput formControlName="new_password" 
        placeholder="Enter atleast 6 characters">
    </mat-form-field>
    <button mat-flat-button color="primary">Save Password</button> 
    <label class="error-message">{{error_message}}</label> 
</form>