import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatDataTableSource } from 'src/app/models/MatDataTableSource';
import { OrderService } from 'src/app/services/order.service';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.css']
})
export class ProductCategoriesComponent implements OnInit {
  categories = new MatTableDataSource;
  table_cols = ['id','category','tools'];
  new_category = '';

  constructor(private orderService : OrderService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.orderService.getAllCategories().subscribe(
      s=>{
        this.categories.data = s as any;
      }
    )
  }

  edit(cat:any){
    cat.editing = true;
  }
  save(cat:any){
    // cat.editing = false;
    if(cat.id)
      this.orderService.category_edit(cat).subscribe(
        (s:any)=>{
          cat.category = s.category;
          cat.editing = false;
        },
        e=> alert(Util.ApiError(e))
      )
  }
  savenew(){
    let cat = this.new_category;
    this.new_category = '';
    this.orderService.category_save(cat).subscribe(
      s=>{
        let ds = this.categories.data;
        ds.splice(0,0,s);
        this.categories.data = ds;
        
      },
      e=> alert(Util.ApiError(e))
    )

  }

}
