
<form (ngSubmit)="login()" [formGroup]="form" class="login-form">
    <mat-form-field appearance="fill">
        <mat-label>E-mail</mat-label>
        <input type="email" matInput formControlName="email" 
        placeholder="Enter e-mail">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password" 
        placeholder="Password">
    </mat-form-field>
    <button mat-flat-button color="primary">Login</button>  
    <a (click)="resetPassword()" style="margin-top: 10px;text-decoration: none;cursor: pointer;color:gray; font-size: smaller;">Reset Password</a>
    <div>
        <label class="error-message">{{error_message}}</label>
    </div>
</form>