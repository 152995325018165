import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { RefcodesService } from 'src/app/services/refcodes.service';


@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.css']
})
export class CheckoutPageComponent implements OnInit {
  show_deliveryAddress= true;

  form = this.fb.group({
    customer_name: ['',[Validators.required]],
    ship_mode : [''],
    delivery_address: [''],
    special_instruction :[''],
    delivery_date : [Date.now()]
  });
cart_items:any = [];
shipping_mode:any = [];

  constructor(private fb: FormBuilder, private router : Router, private orderService: OrderService,
    public refcodes : RefcodesService ) { }

  ngOnInit(): void {

    this.orderService.cart_get()
    .subscribe((cart:any)=>{
       if(cart && cart.items)
        this.cart_items = cart.items;
    });

    this.refcodes.shipping_mode()
      .subscribe(response=>this.shipping_mode = response);
    
  }

  shippingModeChanged(){

    this.show_deliveryAddress = this.form.controls.ship_mode.value!='sm_p';

  }

  saveorder(){

    if(!this.cart_items.length)
      throw 'Cart has not item';

    let items:any = [];
    this.cart_items.forEach((element:any) => {
      items.push({ productCode : element.productCode, qty : element.qty})
    });

    let order = {
      order : {
        customerName : this.form.controls.customer_name.value,
        shipmentMode : this.form.controls.ship_mode.value,
        deliveryAddress : this.form.controls.delivery_address.value,
        specialInstruction : this.form.controls.special_instruction.value,
        deliveryDate : this.form.controls.delivery_date.value,
      },
      items: items,
    };

    this.orderService.order_save(order).subscribe((response:any)=>{
      if(response.order?.referenceNumber)
        this.router.navigateByUrl("/orderinfo?ref_no="+response.order.referenceNumber);
    });
    
  }

}
