import { isNull } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { config } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-cartpage',
  templateUrl: './cartpage.component.html',
  styleUrls: ['./cartpage.component.css']
})
export class CartpageComponent implements OnInit {

  table_data_ready = false;
  cart_items = new MatTableDataSource
  table_cols = ['product_code','product_name','category','qty','remove']
  total_qty = 0;
  total_item = 0;

  constructor(private orderService:OrderService, private router : Router,private changeDetectorRefs: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.orderService.cart_get()
      .subscribe((cart:any)=>{
         if(cart && cart.items)
          this.cart_items.data = cart.items;
        this.table_data_ready = true;
        this.setTotal();
      
      });
  }

  qtyDown(item:any){
    if(item.qty==1)
      return;
    if(item.qty<1){
      item.qty = 1;
      return;
    }
    item.qty = item.qty -1;
    this.orderService.cart_updateitem(item.productCode,item.qty).subscribe(response=>{

    });
    this.setTotal();
  }
  qtyUp(item:any){
    if(item.qty<1){
      item.qty = 1;
      return;
    }
    item.qty = item.qty + 1;
    this.orderService.cart_updateitem(item.productCode,item.qty).subscribe(response=>{
    });
    this.setTotal();
  }
  qtyChanged(item:any,event:any){
    let value =  event.currentTarget.value;
    item.qty = value;
    this.setTotal();
  }
  qtyLostFocus(item:any, event:any){
    let value =  event.currentTarget.value;
    let num_value = parseInt(value);
    num_value = isNaN(num_value) || num_value <1? 1 : num_value;
    this.orderService.cart_updateitem(item.productCode,num_value).subscribe(response=>{
    });
  }
  setTotal(){
    this.total_qty = 0;
    this.total_item = 0;
    let data = this.cart_items.data;
    data.forEach((item:any)=> { 
      let num_value = parseInt(item.qty);
      this.total_qty +=  isNaN(num_value) || num_value<1? 1 : num_value;
    });
    this.total_item =  data.length;
  }
  remove(item_index:any){

    if (confirm("Are you sure you want to remove this item? Press OK to confirm.")) {
      let data = this.cart_items.data;
      let item: any = data[item_index];
      this.orderService.cart_removeitem(item.productCode).subscribe(response => {
        if (response != true)
          return;
        data.splice(item_index, 1);
        this.cart_items.data = data;
        this.setTotal();
      });
    }
  }
  checkout(){
    this.router.navigateByUrl("/checkout");
  }

}
