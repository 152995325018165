import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavBarService } from 'src/app/services/navbar.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { CarticonComponent } from '../carticon/carticon.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit{

  user ={
    show : false,
    username: '',
  }
  @ViewChild(CarticonComponent)
  cartIcon!: CarticonComponent;

  constructor(
    private http: HttpClient,
    private orderService: OrderService,
    private headerService : NavBarService, 
    private userService: UserService,
    private router: Router) { }

  
    
  ngOnInit(): void {
  
    this.refresh();
    
  }
  ngAfterViewInit(): void {

    this.headerService.refresh.subscribe(s=>this.refresh());
    this.headerService.refresh_cart.subscribe(s=>this.cartIcon.refresh());
    
  }

  refresh(){

    //user
    if(this.userService.IsAuthenticated){
      this.user.show = true;
      this.user.username = this.userService.UserName ?? '';
    }
    else{
      this.user.show = false;
      this.user.username = '';
    }
    //cart
    if(this.cartIcon)
      this.cartIcon.refresh();

  } 

  logout(){
    this.userService.logout();
    this.router.navigateByUrl("login");
  }

  changePassword(){
    if(this.userService.IsAuthenticated){
      this.userService.user_requestChangePass(this.userService.UserId).subscribe(
        s=>{
          this.router.navigateByUrl("/changepassword?userid="+this.userService.UserId);
        },
        e=> alert(e)
      )
      return;
    }
    this.userService.logout();
    this.router.navigateByUrl("login");
  }

}
