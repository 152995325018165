
<div style="margin:auto; width: 500px;margin-top: 30px;display: flex; align-items: center; flex-direction: column;">

    <div *ngIf="show_confirmreset">
        <p>Reset password for <b>{{email}}</b>?</p>
        <button mat-flat-button color="accent" (click)="confirmReset()">Yes</button>
        <a href="/" mat-flat-button>Cancel</a>
    </div>

    <form *ngIf="show_reset_section" (ngSubmit)="reset()" [formGroup]="form" class="login-form">
        <label>Reset One-Time-Password was sent to <b>{{email}}</b> valid for 5 minutes. Please enter the code to continue.</label>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Reset OTP</mat-label>
            <input matInput placeholder="One-Time-Password" formControlName="otp" required>
          </mat-form-field>
        <br>
        <button mat-flat-button color="primary">Submit</button>   
    </form>

</div>