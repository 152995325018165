import { Component, ViewChild } from '@angular/core';
import { NavbarComponent } from '../components/navbar/navbar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'GAFNI';

  @ViewChild(NavbarComponent)
  private navBar!: NavbarComponent;

  routeActivated(ev: Event) {
   if(this.navBar)
    this.navBar.refresh();
  }

}
