<mat-toolbar color="primary" class="mainmenu" >

    <mat-toolbar-row>
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <a mat-menu-item routerLink="/products"> Products
            </a>
            <a mat-menu-item routerLink="/categories">Categories
            </a>
            <a mat-menu-item routerLink="/user">Users
            </a>
            <a mat-menu-item routerLink="/settings">Settings</a>
        </mat-menu>

        <a mat-icon-button routerLink="/">Order Page</a>
        <span class="example-spacer"></span>

        <app-carticon></app-carticon>
       
       <ng-container *ngIf="user.show">
        <button  mat-button [matMenuTriggerFor]="usermenu" aria-label="User menu">
            {{user.username}}
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #usermenu="matMenu">
            <button (click)="logout()" mat-menu-item>
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
            <button (click)="changePassword()" mat-menu-item >
                <mat-icon>phonelink_lock</mat-icon>
                <span>Change password</span>
            </button>
        
        </mat-menu>
       </ng-container>
      
    </mat-toolbar-row>

</mat-toolbar>