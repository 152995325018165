import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoleGuard, AuthGuard } from './auth-guard';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { CartpageComponent } from './pages/cartpage/cartpage.component';
import { ChangePasswordPageComponent } from './pages/change-password-page/change-password-page.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { LoginComponent } from './pages/login/login.component';
import { OrderDtlComponent } from './pages/order-dtl/order-dtl.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { ProductCategoriesComponent } from './pages/product-categories/product-categories.component';
import { ProductsComponent } from './pages/products/products.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';

const routes: Routes = [
  { path:'order', component : OrderPageComponent, canActivate: [AuthGuard]},
  { path:'cart', component : CartpageComponent, canActivate:[AuthGuard]},
  { path:'checkout', component : CheckoutPageComponent, canActivate: [AuthGuard]},
  { path:'orderinfo', component : OrderDtlComponent, canActivate: [AuthGuard]},
  { path:'categories', component : ProductCategoriesComponent, canActivate: [AuthGuard, AdminRoleGuard]},
  { path:'products', component : ProductsComponent, canActivate: [AuthGuard, AdminRoleGuard]},
  { path:'user', component : UserManagementComponent, canActivate: [AuthGuard, AdminRoleGuard]},
  { path:'login', component : LoginComponent},
  { path:'resetpassword', component : ResetPasswordPageComponent},
  { path:'changepassword', component : ChangePasswordPageComponent},
  { path:'settings', component : AdminPageComponent, canActivate:[AuthGuard, AdminRoleGuard]},
  { path:'unauthorized', component : UnauthorizedComponent},
  { path:'', component : OrderPageComponent, canActivate:[AuthGuard]},
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
