import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { OrderService } from 'src/app/services/order.service';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  products = new MatTableDataSource;
  table_cols =['product_code','product_name','category','description','tools']
  categories: any;

  constructor(private orderService : OrderService, private snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.orderService.product_getall().subscribe(
      s=>{
        this.products.data = s as any;
      },
      e=> alert(Util.ApiError(e))
    )
    this.orderService.categories_getall().subscribe(
      s=>{
        this.categories = s;
      }
    )
  }
  item_currently_edited : any;
  item_currently_edited_index: any;
  edit(product:any,index:any){
    
    if(this.item_currently_edited){
      this.cancel(this.item_currently_edited,this.item_currently_edited_index);
    }
    this.item_currently_edited = product;
    this.item_currently_edited_index = index;

    product.editing = true;
    if(!product.categories)
      product.categories = this.categories;
  }

  cancel(product:any,index:number){
    product.editing = false;
    if(product.isnew){
      let data = this.products.data;
      data.splice(index,1);
      this.products.data = data;
    }else{

      this.orderService.product_get(product.productCode).subscribe(
        (s:any)=>{
          product.productName = s.productName;
          product.categoryId = s.categoryId;
          product.description = s.description;
          product.categoryName = s.categoryName;
        }
      )
    }

    
  }
  save(product:any, index:number){
    if(product.isnew){
      this.orderService.product_save(product).subscribe(
        (s:any)=>{
          product.productCode = s.productCode;
          product.productName = s.productName;
          product.categoryId = s.categoryId;
          product.brand = s.brand;
          product.categoryName = s.categoryName;
          product.description = s.description;
          product.isnew = false;
          product.editing = false;
          this.snackBar.open(`Product ${product.productCode} created successfully.`,'Okay');
        },
        (e:any)=> alert(Util.ApiError(e))
      )
    }
    else{
      this.orderService.product_edit(product).subscribe(
        (s:any)=>{
          product.productCode = s.productCode;
          product.productName = s.productName;
          product.categoryId = s.categoryId;
          product.brand = s.brand;
          product.categoryName = s.categoryName;
          product.description = s.description;
          product.editing = false;
          this.snackBar.open(`Product ${product.productCode} updated successfully.`,'Okay');
        },
        (e:any)=> alert(Util.ApiError(e))
      )
    }
  }

  new(){

    let new_item = {
      isnew : true,
      categories : this.categories,
    };
    let data = this.products.data;
    let new_item_index = data.splice(0,0,new_item);
    this.products.data = data;
    this.edit(new_item,new_item_index);
  }

}
