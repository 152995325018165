<div  style="margin:10px 20px;">
    <form  [formGroup]="form" class="mat-elevation-z3" >
        <mat-form-field appearance="fill">
            <mat-label>Customer Name</mat-label>
            <input matInput formControlName="customer_name" required>
            <!-- <mat-error *ngIf="form.controls.customer_name.invalid">Customer name is required.</mat-error> -->
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Mode of Shipment</mat-label>
            <mat-select formControlName="ship_mode" (selectionChange)="shippingModeChanged()" required>
              <mat-option *ngFor="let e of shipping_mode" value="{{e.ref_code}}" >{{e.ref_value}}</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="form.controls.ship_mode.invalid">Required</mat-error> -->
        </mat-form-field>
        <mat-form-field *ngIf="show_deliveryAddress" appearance="fill">
            <mat-label>Delivery Address</mat-label>
            <textarea matInput formControlName="delivery_address"></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Special Instruction</mat-label>
            <textarea matInput formControlName="special_instruction"></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Delivery/Pickup Date</mat-label>
            <input matInput type="date" formControlName="delivery_date">
        </mat-form-field>
        <div style="margin:10px;">
            
            <table style="width: 100%;max-width:500px;text-align: left;border-collapse: collapse;">
                <thead>
                    <tr style="background: rgb(228, 228, 228);">
                        <th>#</th>
                        <th>Product Code</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let e of cart_items; index as i">
                        <td> item#{{i+1}}</td> <td>{{e.productCode}}</td> <td>{{e.productName}}</td> <td>{{e.qty}}</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <div>
                <button mat-flat-button  color="primary" (click)="saveorder()">Send Order</button>
            </div>
            <br>
        </div>
        
    </form>
</div>
