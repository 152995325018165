import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class RefcodesService extends BaseService {

  constructor(public http: HttpClient, public config: AppConfig) { super(config, http)}

  shipping_mode(){ return this.http.get(this.ApiUrl('ref/shippingmode'));}
  
}
