import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-carticon',
  templateUrl: './carticon.component.html',
  styleUrls: ['./carticon.component.css']
})
export class CarticonComponent implements OnInit {

  badge!:number;
  hidden = false;
  constructor(private router: Router, private orderService: OrderService) { }

  ngOnInit(): void {
    try{
    this.refresh();
    }catch(err){
     // console.log(err);
    }
  }

  gotocart(){
    this.router.navigateByUrl("/cart");
  }

  refresh(){
    
    this.hidden = true;
    this.orderService.cart_getcount().subscribe(
      (s: any) => {
        this.badge = s;
        this.hidden = s < 1;
      }
    );
  }
}
