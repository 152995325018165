import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
  })
export class AppConfig{

    private _appconfig : any;
    public get api_url() : string {
        return this._appconfig.api;
    }
    constructor(private http:HttpClient){

    }
    
    loadConfig(){
        return this.http.get('/assets/config.json').toPromise()
        .then(data=>this._appconfig = data);
    }
   
    
}