<table *ngIf="order" class="table-order" cellspacing="0" cellpadding="0">
    <colgroup>
        <col style="width: 30%;">
        <col>
    </colgroup>
    <tr>
        <td>Reference No.</td>
        <td>
            {{order.referenceNumber}}
        </td>
    </tr>
    <tr>
        <td>Customer</td>
        <td>{{order.customerName}}</td>
    </tr>
    <tr>
        <td>Shipment</td>
        <td>{{order.shipmentModeDesc}}</td>
    </tr>
    <tr>
        <td>Delivery/Pickup Date</td>
        <td>{{order.deliveryDate | date}}</td>
    </tr>
    <tr>
        <td>Delivery Address</td>
        <td>{{order.deliveryAddress}}</td>
    </tr>

    <tr>
        <td>Special Instruction</td>
        <td>{{order.specialInstruction}}</td>
    </tr>
    <tr>
        <td>Created</td>
        <td>{{order.createdDate | date}}</td>
    </tr>
</table>
<div style="margin:10px">
    <div>
        <button mat-flat-button (click)="email()" color="warn">Resend email</button>
        
    </div>
 
    <p>
        <span *ngFor="let e of items; index as i">
            item#{{ i+1}}: {{e.productCode}}, {{e.productName}} , {{e.qty}} <br>
        </span>
    </p>
</div>

