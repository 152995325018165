
 <div style="margin:10px 20px;">
     <div >
        <table mat-table [dataSource]="table_source" class="mat-elevation-z8" style="width: 100%;">
            <colgroup>
                <col style="width:12%">
                <col style="width:20%">
                <col style="width:40%">
                <col style="width:10%;">
                <col >
                <col>
            </colgroup>
            <ng-container matColumnDef="user_id">
              <th mat-header-cell *matHeaderCellDef>User Id</th>

              <ng-container *matCellDef="let i;let idx = index;">

                <td  mat-cell *ngIf="!i.editing"> 
                    {{i.user_id}}
                </td>

                <td  mat-cell *ngIf="i.editing" colspan="5" > 

                    <div style="display: flex;flex-direction: column;padding:5px;width: 400px;margin: 20px auto;" class="mat-elevation-z2" >
                        <mat-form-field *ngIf="!i.isnew" appearance="fill">
                            <mat-label>User Id</mat-label>
                            <input [disabled]="!i.isnew" matInput type="text" [(ngModel)]="i.user_id">
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>E-mail</mat-label>
                            <input matInput type="text" [(ngModel)]="i.email">
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Name</mat-label>
                            <input matInput type="text" [(ngModel)]="i.fullname">
                        </mat-form-field>
    
                        <div style="display: flex;">
                            <mat-form-field appearance="fill" style="width: 200px;">
                                <mat-label>Role</mat-label>
                                <mat-select [(ngModel)]="i.role">
                                    <mat-option *ngFor="let opt of i.user_roles" [value]="opt.ref_code">
                                        {{opt.ref_value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-checkbox [(ngModel)]="i.active" style="margin:15px 0 0 15px">Active</mat-checkbox>
                        </div>
                       
                        
                        <button (click)="save(i,idx)" mat-flat-button color="primary" aria-label="Save" style="border-radius: 0;">
                            <span *ngIf="i.isnew" >Save</span> 
                            <span *ngIf="!i.isnew">Update</span>
                        </button>
                        <button (click)="cancel_edit(i,idx)" mat-flat-button color="basic" aria-label="Save" style="border-radius: 0;">
                            Cancel
                        </button>
                    </div>

                </td>
              </ng-container>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>E-mail</th>
                <ng-container *matCellDef="let i">
                    <td mat-cell *ngIf="!i.editing">
                        {{i.email}}
                    </td>
                </ng-container>
            </ng-container>
              
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <ng-container *matCellDef="let i">
                    <td mat-cell *ngIf="!i.editing">
                        {{i.fullname}}
                    </td>
                </ng-container>
            
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef>Active</th>
                <ng-container *matCellDef="let i">
                    <td mat-cell *ngIf="!i.editing">
                        <mat-icon *ngIf="i.active" style="color: #0eda0e;">check</mat-icon>
                    </td>
                </ng-container>
            </ng-container>

            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef>
                    <button (click)="new()" mat-icon-button aria-label="New">
                        <mat-icon>add</mat-icon>
                    </button>
                </th>
                <ng-container *matCellDef="let i;let idx = index">
                    <td mat-cell>  
                        <div class="padding:5px;">
                         <button *ngIf="!i.editing" (click)="edit(i,idx)" mat-icon-button color="basic" aria-label="Edit">
                             <mat-icon>edit</mat-icon>
                         </button>
                        </div>
                     </td>
                </ng-container>
                
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="table_cols"></tr>
            <tr mat-row *matRowDef="let row; columns: table_cols;"></tr>
          </table>
     </div>
 </div>
 
  